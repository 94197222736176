<template>
  <div>
    <v-row>
      <v-col>
        <s-crud
          title="Reservas detalladas"
          :filter="filter"
          :config="config"
          excel
        >
          <template v-slot:filter>
            <v-col >
                <v-row justify="center" >
               
               <v-col lg="2" class="s-col-form">
                  <s-select-definition clearable
                    label="Tipo de Plato" noDefault
                    v-model="filter.MltID" :def=1065
                  ></s-select-definition>
                </v-col>

                <v-col lg="2" class="s-col-form">
                  <s-date
                    label="Fecha de Inicio"
                    v-model="filter.BeginDate"
                  ></s-date>
                </v-col>
                <v-col lg="2" class="s-col-form">
                  <s-date
                    label="Fecha de Fin"
                    v-model="filter.EndDate"
                  ></s-date>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="2" class="s-col-form">
                  <s-select
                    dense
                    outlined
                    label="Tipo Planilla"
                    v-model="filter.TypeWorker"
                    hide-details=""
                    :items="itemsPayRoll"
                  ></s-select>
                </v-col>

                <v-col lg="2" class="s-col-form">
                  <s-select-area
                    noDefault
                    full
                    centercost
                    clearable
                    label="Centro de Costo"
                    v-model="filter.CceID"
                  ></s-select-area>
                </v-col>
                <v-col lg="2" class="s-col-form">
                  <s-select-business-line
                    noDefault
                    clearable
                    :cceID="filter.CceID"
                    v-model="filter.BslID"
                  ></s-select-business-line>
                </v-col>

                <v-col lg="3" class="s-col-form">
                  <s-select-area
                    label="Área"
                    clearable
                    v-model="filter.AreID"
                  ></s-select-area>
                </v-col>
              </v-row>
            
            </v-col>
          </template>
       
        </s-crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MealServiceReportDetail from "./../../services/DinningHall//MealConsumptionDetail";
export default {
  props: {},
  data() {
    return {
      itemsPayRoll: [
          { value: 0, text: "TODOS" },
        { value: 14, text: "MENSUAL" },
        { value: 13, text: "SEMANAL" },
        { value: 1, text: "SIN DESCUENTO" },
      ],
      TypeCost: 1,
      filter: {
        BeginDate: null,
        EndDate: null,
        AreID: 0,
        TypeWorker: 0,
        AreID: 0,
        BslID: 0,
        CceID: 0,
      },
      config: {
        model: {
          McsID: "ID",
          McsConsumptionDate: "datetime",
          SecCreate: "datetime",
          McsStatus: "booleam",McsReservationDate:'date'
        },
        service: MealServiceReportDetail,
        headers: [
          { text: "ID", value: "McsID" },
          { text: "Documento", value: "McsPersonDocumentNumber" },
          { text: "Nombre Completo", value: "McsPersonName" },
          { text: "Fecha", value: "McsReservationDate" },
          //   { text: "FechaReserva", value: "SecCreate" },
          // { text: "FechaConsumo", value: "McsConsumptionDate" },
          { text: "TPlato", value: "MealTypeText" },
          //   { text: "TMenu", value: "MenuTypeText" },
          { text: "TReserva", value: "TypeReservationText" },
      
        //  { text: "OpReserva", value: "OptionReserveText" },
          { text: "Desct", value: "McsDiscount",IsLevelAdmin:true},
          { text: "TCosto", value: "TypeCostText" },
        ],
      },
    };
  },
  methods: {},

  created() {},
  watch: {},
};
</script>
